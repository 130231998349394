#home-description {
    background-image: linear-gradient(rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.01)), url('../../static/images/home_cover.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 3em;
}
#testimonials-cover {
    background-image: linear-gradient(rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.01)), url('../../static/images/testimonials_cover.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-bottom: 3em;
}
.text-container {
    color: black;
    text-align: center;
}
.text-container .text-container-header {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: black;
}
.text-container .text-container-paragraph {
    font-size: 18px;
    line-height: 32px;
}
.skills-overview-container {
    color: black;
    text-align: center;
}
.skills-overview-container .text-container-header {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: black;
}
.skills-overview-container .skills-item:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 5px 10px;
    transform: translate3d(0px, -1px, 0px);
}
.skills-overview-container .skills-item-title {
    font-weight: bold;
    font-size: 18px;
}
.skills-overview-container .skills-item-description {
    font-size: 16px;
}
.testimonials-container {
    color: black;
    text-align: center;
}
.testimonials-container .ui[class*="two column"].grid>.column:not(.row), .ui[class*="two column"].grid>.row>.column.testimonials-item {
    width: 70%;
}
.testimonials-container .testimonials-item:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 5px 10px;
    transform: translate3d(0px, -1px, 0px);
}
.testimonials-container .text-container-header {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
    color: black;
}
.testimonials-container .testimonials-item-right {
    margin-left: auto;
}
.testimonials-container .testimonials-item-center {
    margin-left: auto;
    margin-right: auto;
}
.testimonials-container .testimonials-item-text {
    font-style: italic;
    font-size: x-large;
    margin-bottom: 0;
    text-align: left;
}
.testimonials-container .testimonials-item-from {
    font-weight: bold;
    font-size: small;
    text-align: left;
}
.home-container .margin-top-3em {
    margin-top: 3em;
}
.home-container .margin-bottom-3em {
    margin-bottom: 3em;
}
.home-container .font-family-bebas-neue {
    font-family: bebas-neue;
    letter-spacing: 0.5px;
}